import isPropValidEmotion from '@emotion/is-prop-valid';
var STANDARDIZED_PROPS = ['fetchpriority'];
var isPropValid = function (prop) {
    return isPropValidEmotion(prop) || STANDARDIZED_PROPS.includes(prop);
};
export var getForwardPropOpts = function (propsToFilter, respectValidity) {
    if (propsToFilter === void 0) { propsToFilter = []; }
    if (respectValidity === void 0) { respectValidity = true; }
    return ({
        shouldForwardProp: function (currentProp) {
            var isPropFound = propsToFilter.includes(currentProp);
            return respectValidity
                ? isPropValid(currentProp) && !isPropFound
                : !isPropFound;
        },
    });
};
